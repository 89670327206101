<template>
  <div>
      <LiefengContent>
        <template v-slot:title>平台用户管理</template>
        <template v-slot:toolsbarRight>
                <!-- <Input
                  :maxlength="20"
                  enter-button
                  placeholder="请输入类型名称"
                  style="margin-right:10px;width: 150px"
                  v-model="search.name"
                />
                <Input
                  :maxlength="20"
                  width="100px"
                  placeholder="请输入类型编码"
                  style="margin-right:10px;width:150px"
                  v-model="search.code"
                />
              <Button type="primary" icon="ios-search" @click="searchBtn">搜索</Button>
              <Button type="success" icon="ios-refresh" @click="restBtn">重置</Button> -->
              <Button type="info" icon="ios-eye" style="margin-right:10px" @click="checkInvation">查看平台邀请码</Button>
              <Button type="primary"  icon="ios-add"  style="margin-right: 10px" @click="addBtn">新增</Button>
             
        </template>
        <!--身体部分-->
        <template v-slot:contentArea>
             <div class="container">
                 <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[50, 200, 500, 1000]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
             </div>
        <LiefengModal  :fullscreen="false" title="新增平台用户" @input="addModalFn" :value="addModal.status">
          <template #contentarea>
              <Form class="search" :label-width="100" :inline="true">
                    <FormItem>
                    <span slot="label" class="validate">邀请码:</span>
                    <Input v-model="search.name" placeholder="填输入邀请码" style="width:400px"></Input>
                    </FormItem>
              </Form>
          </template>
          <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="addModal.tip = true;addModalFn(false)">取消</Button>
            <Button type="primary" @click="addSave">确定</Button>
          </template>
        </LiefengModal>
        <LiefengModal :fullscreen="false" title="修改用户角色" @input="changeRoleFn" :value="changeModal.status">
            <template #contentarea>
                <Form class="search" :label-width="150" :inline="true">
                    <FormItem>
                        <span slot="label" class="validate">请选择用户角色:</span>
                        <Select v-model="selectRole" multiple style="width:260px">
                            <Option v-for="item in options" :value="item.roleCode" :key="item.roleCode">{{ item.roleName }}</Option>
                        </Select>
                    </FormItem>             
                </Form>
            </template>
             <template #toolsbar>
                <Button type="info" style="margin-right: 10px" @click="changeRoleFn(false)">取消</Button>
                <Button type="primary" @click="editSave">确定</Button>
          </template>
        </LiefengModal>
        <LiefengModal :fullscreen="false" title="查看平台邀请码" @input="changeInvationFn" :value="changeInvationStatus">
            <template #contentarea>
                <div class="chech-div">
                    {{paraValue || '------'}}
                </div>
            </template>
            <template #toolsbar>
                <Button type="primary" @click="changeInvationFn(false)">确定</Button>
          </template>
        </LiefengModal>
        <LiefengModal :fullscreen="false" title="修改审核" @input="changeStatus" :value="shenheStatus" >
            <template #contentarea>
                <Form class="search" :label-width="150" :inline="true">
                    <FormItem>
                        <span slot="label" class="validate">请选择审核状态:</span>
                         <Select v-model="selectStatus" style="width:260px">
                            <Option v-for="item in shenheOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
                        </Select>
                    </FormItem>             
                </Form>
               
            </template>
            <template #toolsbar>
                <Button type="info" style="margin-right: 10px" @click="changeStatus(false)" >取消</Button>
                <Button type="primary" @click="changeSave" >确定</Button>
          </template>
        </LiefengModal>
        </template>
      </LiefengContent>
  </div>
</template>

<script>
  //@route("/platformuserindex")
  import LiefengContent from "@/components/LiefengContent";
  import LiefengTable from "@/components/LiefengTable";
  import LiefengModal from "@/components/LiefengModal";
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {LiefengContent,LiefengTable,LiefengModal},
    data() {
      //这里存放数据
      return {
          talbeColumns:[
              {
                  title:'真实名称',
                  key:'name',
                  minWidth:200,
                  align:"center"
              },
              {
                title: "昵称",
                key: "nickName",
                minWidth: 200,
                align: "center"
            },
              {
                title: "手机号",
                  key: "mobile",
                  minWidth: 200,
                  align: "center"
              },
              {
                  title:'性别',
                  key:'sexValue',
                  minWidth:100,
                  align:'center'
              },
              {
                  title:'用户角色',
                  minWidth:200,
                  align:'center',
                  render:(h,params)=>{
                      return h('div',{},params.row.releName.join(','))
                  }
              },
              {
                  title:'审核状态',
                  minWidth:200,
                  align:'center',
                  render:(h,params)=>{
                      return h ('div',{},params.row.status == 1 ?'已开通':params.row.status == 2 ?'禁止':params.row.status == 3?'等待审核':'')
                  }
              },
            {
                title:'操作',
                align:'center',
                minWidth:200,
                fixed:'right',
                render:(h,params) =>{
                     return h(
                        "div",
                        {
                            style: {
                            textAlign: "left",
                            display:'flex',
                            justifyContent:'center'
                            },
                        },
                        [
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.selectRole = []
                                        this.selectRole = params.row.selectRole
                                        this.getDetail(params.row.tenantUserId)
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "修改用户角色" //1001
                            ),  
                            h("Button",{
                                attrs:{
                                    type:'info',
                                    size:'small',
                                },
                                on:{
                                    click:()=>{
                                        this.selectStatus = params.row.status
                                        this.shenheRow.tenantUserId = params.row.tenantUserId
                                        this.shenheRole = []
                                        this.shenheRole = params.row.selectRole
                                        this.shenheStatus = true
                                    }
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },'审核'),     
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "error",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                       this.$Modal.confirm({
                                           title: "温馨提示",
                                            content: "<p>确认要删除该数据吗？</p>",
                                            onOk: () => {
                                                this.deleteRow(params.row.tenantUserId)
                                            },
                                       })
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "删除" //1001
                            ),       
                         ]
                     );
                }
            }
          ],
          tableData:[],
          loading:false,
          page:1,
          total:0,
          pageSize:20,
          currentPage:0,
          search:{
            name:'',
            code:''
          },
          // 新增家庭参数
        addModal:{
         status:false,
         tip:false
        },
        options:[],
        selectRole:[],
        changeModal:{
            status:false,
            tip:false
        },
        token:'',
        detailMessage:{},
        changeInvationStatus:false,
        paraValue:'',
        shenheStatus:false,
        shenheOptions:[
            {
                value:'1',
                label:'已开通'
            },
            {
                value:'3',
                label:'等待审核'
            },
        ],
        selectStatus:'',
        shenheRow:{},
        shenheRole:[]
      }
    },
    //方法集合
    methods: {
        // 审核模态框确认按钮
        changeSave(){
            if(this.selectStatus == ''){
                this.$Message.warning({
                    content:'请选择审核状态',
                    background:true
                })
                return
            }else{
                let data = {
                    tenantUserId:this.shenheRow.tenantUserId,
                    status : this.selectStatus
                }
                this.updateShenhe(data)
            }
        },
        // 关闭审核模态框
        changeStatus(status){
            this.shenheStatus = status
        },
        // 关闭查看邀请码模态框
        changeInvationFn(status){
            this.changeInvationStatus = status
        },
        // 点击查看邀请码按钮
        checkInvation(){
            this.changeInvationStatus = true
            this.getInvation()
        },
        // 修改用户角色
        editSave(){
            if(this.selectRole.length == 0){
                this.$Message.warning({
                    content:'请选择用户角色',
                    background:true
                })
                return
            }else{
                this.updateRow()
            }
            
        },
        changeRoleFn(status){
           
                this.changeModal.status = status;
            
        },
        // 新增模态框确认按钮
        addSave(){
            if(this.search.name == ''){
                 this.$Message.warning({
                    content:'邀请码不能为空',
                    background:true
                })
                return
            }else{
                this.addPlatform(this.search.name)
            }
            
        },
        //关闭家庭模态框事件
        addModalFn(status) {
         
                this.addModal.status = status;
           
        
        },   
        // 点击新增按钮
        addBtn(){
            this.addModal.status  = true
             this.search.name = ''  
        },
        hadlePageSize(val){
          this.page = val.page
          this.pageSize = val.pageSize
          this.getList()
        },

        // // 接口部分
        getList(){
            this.loading = true
            this.$get('/gateway/api/auth/pc/TenantUserApi/selectByPage',{
              page:this.page,
              pageSize:this.pageSize
            },{
                Authorization:this.token
            }).then(res=>{
                if(res.code == 200){
                    this.loading = false
                    this.tableData  = res.dataList
                    if(this.tableData && this.tableData.length != 0){
                        this.tableData.map(item =>{
                            item.releName = []
                            item.selectRole = []
                            if(item.roles && item.roles.length != 0){
                            let obj1 = {}   
                            let res1 = []
                              item.roles.forEach(items =>{
                                  if(!obj1[items.roleCode]){
                                      res1.push(items)
                                      item.releName.push(items.roleName)
                                      item.selectRole.push(items.roleCode)
                                    obj1[items.roleCode] = true
                                  }
                              })
                                item.roles = res1
                            }
                        })
                    }
                    console.log(this.tableData);
                    
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                }else{
                    this.loading = false
                    this.$Message.error({
                        content:res.msg,
                        background:true
                    })
                    return
                }
                
            })
        },
        // 删除接口
        deleteRow(data){
            this.$post('/gateway/api/auth/pc/TenantUserApi/deleteTenantUser',{
                tenantUserId :data
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    this.page = this.currentPage
                    this.getList()
                    return
                }else{
                    this.$Message.error({
                        background:true,
                        content:res.msg
                    })
                    return
                }
            })
        },
        // 获取详情接口
        getDetail(data){
            this.$get('/gateway/api/auth/pc/TenantUserApi/selectById',{
                tenantUserId :data
            },{
                Authorization:this.token
            }).then(res=>{
                if(res.code == 200){
                    this.changeModal.status = true
                    
                    this.detailMessage= res.data
                    // if(res.data.roles && res.data.roles.length !=0){
                    //     res.data.roles.map(item =>{
                    //         this.selectRole.push(item.roleCode)
                    //     })
                    // }
                }else{
                    if(res.desc || res.desc == null){
                        this.$Message.error({
                            content:'打开详情失败',
                            background:true
                        })
                    }else{
                         this.$Message.error({
                            content:res.msg,
                            background:true
                        })
                    }                 
                }
            })
        },
        // 新增平台
        addPlatform(data){
            this.$post('/gateway/api/auth/pc/TenantUserApi/inviteUserJoin',{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                inviteCode:data
            },{
                Authorization:this.token
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'新增平台成功',
                        background:true
                    })
                    this.page = 1
                    this.pageSize = 20
                    this.getList()
                    this.addModal.status = false;
                    this.addModal.tip = false  
                    return
                }else{
                    this.$Message.error({
                        content:res.msg,
                        background:true
                    })
                    return
                }
            })
        },
        getAuth(){
            this.$get('/gateway/api/auth/pc/auth/selectUserHaveRole',{},{
                Authorization:this.token
            }).then(res=>{
                if(res.code == 200){
                    this.options = res.dataList
                }else{
                    this.$Message.error({
                        content:res.msg,
                        background:true
                    })
                    return
                }
            })
        },
        // 修改用户角色接口
        updateRow(){
            this.$post('/gateway/api/auth/pc/TenantUserApi/changeTenantUser',{
                roleCodes : this.selectRole.join(','),
                status :this.detailMessage.status,
                tenantUserId :this.detailMessage.tenantUserId 
            },{ Authorization:this.token,"Content-Type": "application/x-www-form-urlencoded"}).then(res=>{
                if(res.code == 200){
                    this.page = this.currentPage
                    this.getList()
                    this.changeModal.status = false
                    this.$Message.success({
                        content:'修改用户角色成功',
                        background:true
                    })
                    return
                }else{
                    this.$Message.error({
                        background:true,
                        content:res.msg
                    })
                    return
                }
            })
        },
        // 获取邀请码接口
        getInvation(){
            this.$get('/gateway/api/auth/pc/TenantApi/generateInvitationCode',{},{Authorization:this.token,"Accept": ""}).then(res=>{
                if(res.code == 200){
                     this.paraValue = res.data.paraValue
                }else{
                    this.$Message.error({
                        content:res.msg,
                        background:true
                    })
                    return
                }
               
            })
        },
        // 审核状态接口
        updateShenhe(data){
             this.$post('/gateway/api/auth/pc/TenantUserApi/changeTenant',{
                status :data.status,
                tenantUserId :data.tenantUserId
            },{ Authorization:this.token,"Content-Type": "application/x-www-form-urlencoded"}).then(res=>{
                if(res.code == 200){
                    this.page = this.currentPage
                    this.getList()
                    this.shenheStatus = false
                    this.$Message.success({
                        content:'审核成功成功',
                        background:true
                    })
                    return
                }else{
                    this.$Message.error({
                        background:true,
                        content:res.msg
                    })
                    return
                }
            })
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      this.token = 'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIxNzMyODY4MDAyNCIsInNjb3BlIjpbImFsbCJdLCJleHAiOjE2MjQzODIwMTcsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJqdGkiOiI3ODEwNGUwMi03NGVhLTRhMGEtYWMxMy1kNjE2MmE1NjA4YTEiLCJjbGllbnRfaWQiOiJzdWlhbmp1IiwidG9rZW4iOiJzdWlhbmp1bG9naW5fdG9rZW5fZWIzYjY2YmQ4OTk5NDJlNTkxNTRiYTY2MGQ0N2M3ZTAifQ.joanaPFF1Ini2jj2-TqvyK7zpR-TG0UVOo0rxndJxmCrGJ26ZsQMIo7z0FingkuXh3B4FzYHuFKIvEaZhUa1z8oS3yivtxFCcepfGwoMQHn05DYRt-CA5NlsZio3f4BWNNZZmqY4MMTDpTCWEBoGtNQpaZIdxPK4FO9B2A8NmZo'
      this.getList()
      this.getAuth()
    },
  }
</script>
<style lang='less' scoped>
  .chech-div{
          height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 50px;
  }
  //编写css
  /deep/ #modal_contentarea{
        overflow: visible !important;
    }
</style>